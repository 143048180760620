<template>
  <div class="navigator-container">
    <div class="navigator-content">
      <b-button
        id="mortgageFlowNavigationButtonPrev"
        class="prev-btn"
        @click="previousStep"
        v-if="!withoutButtons"
        :disabled="!prevVisible || isBackDisabledByKyc"
        type="is-text"
      >
        <b-icon
          class="prev-btn--icon"
          icon-pack="fas"
          icon="angle-left"
        ></b-icon>

        <span class="prev-btn--label">{{
          $t("af:mortgage_flow_navigator.button.previous_step")
        }}</span>
      </b-button>

      <div class="progress-bar">
        <p class="is-size-7 has-text-centered">
          <span class="has-text-weight-medium"
            >{{
              currentStepIndex + 1 + stepCorrection - previousHiddenSteps
            }}/{{ totalSteps }}
            {{ $t("af:mortgage_flow_navigator.label.step") }}</span
          >
          <span class="has-text-grey-icon ml-1" v-if="remainingMinutes > 0"
            >({{ remainingMinutes }}
            {{ $t("af:mortgage_flow_navigator.label.minute") }})</span
          >
        </p>
        <b-progress
          type="is-green"
          :value="progress"
          size="is-small"
        ></b-progress>
      </div>
      <b-button
        v-if="nextVisible && !withoutButtons"
        id="mortgageFlowNavigationButtonNext"
        :disabled="
          /*!isPsd2Synced &&*/ currentStepHasClosedBarrier || !isKycCompleted
        "
        class="next-btn"
        type="is-blue"
        @click="nextStep"
        >{{ $t("af:mortgage_flow_navigator.button.next_step") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import {
  FLOW_POSITION,
  FLOW_QUESTION_FIELD_TYPE,
  FLOW_QUESTION_FIELD_SUBTYPE,
} from "@/utils/const";
import { mapActions, mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import eventBus, { EVENTS } from "@/utils/eventBus";

export default {
  name: "MortgageApplicationFlowNavigator",
  props: {
    applicationId: {
      type: Number,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    currentStepIndex: {
      type: Number,
      required: true,
    },
    flowPosition: {
      type: String,
      required: true,
    },
    withoutButtons: {
      type: Boolean,
      default: false,
    },
    isOnline: {
      type: Boolean,
      default: false,
    },
    hasScoring: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    scoringCorrection() {
      return this.hasScoring ? 1 : 0;
    },
    stepCorrection() {
      switch (this.flowPosition) {
        case FLOW_POSITION.INTRO:
          return -1;
        case FLOW_POSITION.FLOW:
          return 0;
        case FLOW_POSITION.OVERVIEW:
          return 1;
        case FLOW_POSITION.SCORING:
          return 2;
        case FLOW_POSITION.OFFLINE_UNDER_REVIEW:
        case FLOW_POSITION.OFFLINE_RESULT:
          return 2 + this.scoringCorrection;
        // case FLOW_POSITION.OFFLINE_RESULT:
        // case FLOW_POSITION.CONTRACT_CHECK:
        //   return 3 + this.scoringCorrection;
        // case FLOW_POSITION.SIGN_CHOOSE:
        // case FLOW_POSITION.SIGN_PREPARATION:
        // case FLOW_POSITION.SIGN_WAITING:
        //   return 4 + this.scoringCorrection;
        // case FLOW_POSITION.UNDER_REVIEW:
        //   return 5 + this.scoringCorrection;
        // case FLOW_POSITION.RESULT:
        // case FLOW_POSITION.PURCHASE_RESULT:
        // case FLOW_POSITION.CLOSED:
        //   return 6 + this.scoringCorrection;
      }
      return 0;
    },
    previousHiddenSteps() {
      console.log(this.currentStepIndex);
      return this.steps.filter(
        (step) => step.rowIndex < this.currentStepIndex && step.isHidden
      ).length;
    },
    nextVisible() {
      if (
        this.flowPosition === FLOW_POSITION.SIGN_WAITING ||
        this.flowPosition === FLOW_POSITION.SIGN_CHOOSE ||
        this.flowPosition === FLOW_POSITION.UNDER_REVIEW ||
        this.flowPosition === FLOW_POSITION.OFFLINE_UNDER_REVIEW ||
        this.flowPosition === FLOW_POSITION.CONTRACT_CHECK ||
        this.flowPosition === FLOW_POSITION.RESULT ||
        this.flowPosition === FLOW_POSITION.SCORING ||
        this.flowPosition === FLOW_POSITION.OFFLINE_RESULT ||
        this.flowPosition === FLOW_POSITION.PURCHASE_RESULT ||
        this.flowPosition === FLOW_POSITION.CLOSED
      )
        return false;
      return true;
    },
    prevVisible() {
      if (this.flowPosition === FLOW_POSITION.FLOW) {
        return this.currentStepIndex !== 0;
      }
      if (this.flowPosition === FLOW_POSITION.OVERVIEW) {
        return true;
      }
      if (
        this.flowPosition === FLOW_POSITION.SIGN_PREPARATION ||
        this.flowPosition === FLOW_POSITION.SIGN_CHOOSE
      ) {
        return true;
      }
      return false;
    },
    currentStepHasClosedBarrier() {
      const barrier = this.steps[this.currentStepIndex].questions.find(
        (q) =>
          q.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
          q.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.BARRIER
      );

      if (!barrier) {
        return false;
      } else {
        return !this.isBarrierOpen(this.applicationId, barrier.id);
      }
    },
    isPsd2Synced() {
      let isSynced = true;
      let fieldRefs = this.$store.getters["psd2Sync/getFieldRefs"];
      if (fieldRefs) {
        Object.values(fieldRefs).forEach((fieldRef) => {
          let isFieldSynced = false;
          if (fieldRef.skippable && fieldRef.skipped) {
            isFieldSynced = true;
          } else if (fieldRef.isSynced()) {
            isFieldSynced = true;
          }

          if (!isFieldSynced) {
            isSynced = false;
          }
        });
      }
      return isSynced;
    },
    isKycCompleted() {
      if (this.flowPosition !== FLOW_POSITION.FLOW) {
        return true;
      }
      const kycField = this.steps[this.currentStepIndex].questions.find(
        (q) =>
          q.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
          q.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.KYC
      );

      if (!kycField) {
        return true;
      } else {
        return this.$store.getters["mortgageApplication/kycCompleted"];
      }
    },
    isBackDisabledByKyc() {
      if (this.flowPosition === FLOW_POSITION.OVERVIEW) {
        // if Overview, disable if last step is kyc
        return this.steps[this.currentStepIndex].questions.find(
          (q) =>
            q.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            q.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.KYC
        );
      }
      if (this.flowPosition !== FLOW_POSITION.FLOW) {
        return false;
      }

      const previousStepContainsKycField =
        this.steps[this.currentStepIndex - 1] &&
        this.steps[this.currentStepIndex - 1].questions.find(
          (q) =>
            q.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
            q.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.KYC
        );

      if (previousStepContainsKycField) {
        return true;
      }

      const kycField = this.steps[this.currentStepIndex].questions.find(
        (q) =>
          q.fieldType === FLOW_QUESTION_FIELD_TYPE.SPECIAL_FIELD &&
          q.fieldSubtype === FLOW_QUESTION_FIELD_SUBTYPE.KYC
      );

      if (kycField) {
        return (
          this.$store.getters["mortgageApplication/kycStarted"] ||
          this.$store.getters["mortgageApplication/kycCompleted"] ||
          this.$store.getters["mortgageApplication/kycWaitingFor4Eyes"]
        );
      } else {
        return false;
      }
    },
    totalSteps() {
      if (!this.isOnline) {
        return (
          this.steps.filter((step) => !step.isHidden).length +
          2 +
          this.scoringCorrection
        ); // OVERVIEW, REVIEW
      }
      return (
        this.steps.filter((step) => !step.isHidden).length +
        4 +
        this.scoringCorrection
      ); // OVERVIEW, CONTRACT, SIGN, REVIEW
    },
    progress() {
      if (this.flowPosition === FLOW_POSITION.INTRO) {
        return 0;
      }
      if (this.flowPosition === FLOW_POSITION.FLOW) {
        return (
          ((this.currentStepIndex + this.stepCorrection + 1) /
            this.totalSteps) *
          100
        );
      }
      if (this.flowPosition === FLOW_POSITION.OVERVIEW) {
        return (
          ((this.currentStepIndex + this.stepCorrection + 1) /
            this.totalSteps) *
          100
        );
      }
      if (this.flowPosition === FLOW_POSITION.OFFLINE_UNDER_REVIEW) {
        return (
          ((this.currentStepIndex + this.stepCorrection + 1) /
            this.totalSteps) *
          100
        );
      }
      if (this.flowPosition === FLOW_POSITION.CONTRACT_CHECK) {
        return (
          ((this.currentStepIndex + this.stepCorrection + 1) /
            this.totalSteps) *
          100
        );
      }
      if (
        this.flowPosition === FLOW_POSITION.SIGN_PREPARATION ||
        this.flowPosition === FLOW_POSITION.SIGN_CHOOSE ||
        this.flowPosition === FLOW_POSITION.SIGN_WAITING
      ) {
        return (
          ((this.currentStepIndex + this.stepCorrection + 1) /
            this.totalSteps) *
          100
        );
      }

      if (this.flowPosition === FLOW_POSITION.UNDER_REVIEW) {
        return (
          ((this.currentStepIndex + this.stepCorrection + 1) /
            this.totalSteps) *
          100
        );
      }

      if (this.flowPosition === FLOW_POSITION.SCORING) {
        return (
          ((this.currentStepIndex + this.stepCorrection + 1) /
            this.totalSteps) *
          100
        );
      }
      if (
        this.flowPosition === FLOW_POSITION.RESULT ||
        this.flowPosition === FLOW_POSITION.OFFLINE_RESULT ||
        this.flowPosition === FLOW_POSITION.PURCHASE_RESULT ||
        this.flowPosition === FLOW_POSITION.CLOSED
      ) {
        return 100;
      }
      return (
        (this.currentStepIndex + this.stepCorrection + 1) / this.totalSteps
      ); // default
    },
    totalSecondsNeeded() {
      let total = 300 + 1200 + 60;
      // contract + sign + a minute

      if (!this.isOnline) {
        total = 600 + 60;
      }

      this.steps.forEach(
        (step) =>
          (total +=
            !step.isHidden && step.estimatedTimeToAnswerInSeconds
              ? step.estimatedTimeToAnswerInSeconds
              : 0)
      );

      return total;
    },
    remainingMinutes() {
      let elapsedSeconds = 0;
      for (let i = 0; i < this.currentStepIndex && i < this.steps.length; i++) {
        elapsedSeconds += this.steps[i].estimatedTimeToAnswerInSeconds ?? 0;
      }
      if (
        this.flowPosition === FLOW_POSITION.CONTRACT_CHECK ||
        this.flowPosition === FLOW_POSITION.OVERVIEW ||
        this.flowPosition === FLOW_POSITION.SCORING
      ) {
        elapsedSeconds += 600;
      }
      if (
        this.flowPosition === FLOW_POSITION.SIGN_PREPARATION ||
        this.flowPosition === FLOW_POSITION.SIGN_CHOOSE ||
        this.flowPosition === FLOW_POSITION.SIGN_WAITING ||
        this.flowPosition === FLOW_POSITION.OFFLINE_UNDER_REVIEW ||
        this.flowPosition === FLOW_POSITION.OFFLINE_RESULT
      ) {
        elapsedSeconds += 600 + 300;
      }
      if (this.flowPosition === FLOW_POSITION.UNDER_REVIEW) {
        elapsedSeconds += 600 + 300 + 1200;
      }
      if (
        this.flowPosition === FLOW_POSITION.RESULT ||
        // this.flowPosition === FLOW_POSITION.PURCHASE_RESULT ||
        this.flowPosition === FLOW_POSITION.CLOSED
      ) {
        return 0;
      }
      return Math.floor((this.totalSecondsNeeded - elapsedSeconds) / 60);
    },
    ...mapGetters({
      isBarrierOpen: "mortgageApplication/isBarrierOpen",
    }),
  },
  methods: {
    ...mapActions({
      savePosition: "mortgageApplication/savePosition",
    }),
    async nextStep() {
      if (this.flowPosition === FLOW_POSITION.FLOW) {
        eventBus.$emit(EVENTS.FLOW_VALIDATION);
        if (
          !this.$store.getters["mortgageApplication/canContinue"](
            this.currentStepIndex
          )
        ) {
          this.$buefy.toast.open({
            message: i18n.t(
              "af:mortgage_flow_navigator.message.validator.all_fields_required"
            ),
            type: "is-danger",
            position: "is-bottom",
          });
          return;
        }
        if (this.currentStepIndex === this.steps.length - 1) {
          await this.savePosition({
            id: this.applicationId,
            flowPosition: FLOW_POSITION.OVERVIEW,
            currentFlowStepIndex: this.currentStepIndex,
          });
        } else {
          await this.savePosition({
            id: this.applicationId,
            flowPosition: FLOW_POSITION.FLOW,
            currentFlowStepIndex: this.currentStepIndex + 1,
          });
        }
      } else if (this.flowPosition === FLOW_POSITION.INTRO) {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.FLOW,
          currentFlowStepIndex: 0,
        });
      } else if (this.flowPosition === FLOW_POSITION.OVERVIEW) {
        /*
        if (this.hasScoring) {
          await this.savePosition({
            id: this.applicationId,
            flowPosition: FLOW_POSITION.SCORING,
            currentFlowStepIndex: this.currentStepIndex,
          });
          //szándékosan nincs await, hadd induljon el a háttérben
          this.$store.dispatch("mortgageApplication/startScoringEvaluation");
        } else {
        */
        if (this.isOnline) {
          await this.savePosition({
            id: this.applicationId,
            // flowPosition: FLOW_POSITION.KYC_PREPARATION,
            // KYC to field!
            flowPosition: FLOW_POSITION.CONTRACT_CHECK,
            currentFlowStepIndex: this.currentStepIndex,
          });
        } else {
          await this.savePosition({
            id: this.applicationId,
            flowPosition: FLOW_POSITION.OFFLINE_UNDER_REVIEW,
            currentFlowStepIndex: this.currentStepIndex,
          });
        }
        //}
        this.track("mortgage_application_submitted");
      } else if (this.flowPosition === FLOW_POSITION.CONTRACT_CHECK) {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.SIGN_PREPARATION,
          currentFlowStepIndex: this.currentStepIndex,
        });
      } else if (this.flowPosition === FLOW_POSITION.SIGN_PREPARATION) {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.SIGN_CHOOSE,
          currentFlowStepIndex: this.currentStepIndex,
        });
      } else if (this.flowPosition === FLOW_POSITION.SIGN_CHOOSE) {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.SIGN_WAITING,
          currentFlowStepIndex: this.currentStepIndex,
        });
      } else if (this.flowPosition === FLOW_POSITION.SIGN_WAITING) {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.UNDER_REVIEW,
          currentFlowStepIndex: this.currentStepIndex,
        });
      }

      await this.$store.dispatch("mortgageApplication/fetchApplication");
      await this.$store.dispatch("myTodo/fetchMortgageCount");
    },
    async previousStep() {
      if (this.flowPosition === FLOW_POSITION.FLOW) {
        if (this.currentStepIndex === 0) {
          await this.savePosition({
            id: this.applicationId,
            flowPosition: FLOW_POSITION.INTRO,
            currentFlowStepIndex: 0,
          });
        } else {
          await this.savePosition({
            id: this.applicationId,
            flowPosition: FLOW_POSITION.FLOW,
            currentFlowStepIndex: this.currentStepIndex - 1,
          });
        }
      } else if (this.flowPosition === FLOW_POSITION.INTRO) {
        // noop();
      } else if (this.flowPosition === FLOW_POSITION.OVERVIEW) {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.FLOW,
          currentFlowStepIndex: this.currentStepIndex,
        });
      } else if (this.flowPosition === FLOW_POSITION.CONTRACT_CHECK) {
        // noop();
      } else if (this.flowPosition === FLOW_POSITION.SIGN_PREPARATION) {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.CONTRACT_CHECK,
          currentFlowStepIndex: this.currentStepIndex,
        });
      } else if (this.flowPosition === FLOW_POSITION.SIGN_CHOOSE) {
        await this.savePosition({
          id: this.applicationId,
          flowPosition: FLOW_POSITION.SIGN_PREPARATION,
          currentFlowStepIndex: this.currentStepIndex,
        });
      } else if (this.flowPosition === FLOW_POSITION.SIGN_WAITING) {
        // noop();
      }
      await this.$store.dispatch("myTodo/fetchMortgageCount");
    },
    track(eventName) {
      this.$gtag.event(eventName);
    },
  },
};
</script>

<style scoped lang="scss">
.navigator-container {
  background: white;
}
</style>
